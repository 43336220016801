import { Tooltip, Typography } from '@mui/material'
import { LocalizedTypography } from 'components/Localization/LocalizedTypography'
import { ProjectStateAsString } from 'controllers/Project/ProjectStateMachine'
import { ExpirationDateProgressVariant } from 'features/BillOfMaterials/QuotationExpirationDate/ExpirationDateProgressVariant'
import { MRT_ColumnDef } from 'material-react-table'
import { MoneyString } from 'model/Money'
import moment from 'moment'
import { ReactNode, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { ProjectSummaryDto } from 'services/APIs/InternalAPI/internal-api.contracts'

const statesToRenderExpirationDate: ProjectStateAsString[] = [
  ProjectStateAsString.CANCELLED,
  ProjectStateAsString.DEFINING,
  ProjectStateAsString.REQUESTED,
  ProjectStateAsString.QUOTING,
  ProjectStateAsString.QUOTED,
  ProjectStateAsString.NEGOTIATING,
]

const DefaultTypography = (props: { renderedCellValue: ReactNode }) => {
  return (
    <Tooltip title={props.renderedCellValue}>
      <Typography
        variant="body2"
        whiteSpace={'pre-wrap'}
        style={{
          overflow: 'hidden',
          maxWidth: '9vw',
          whiteSpace: 'nowrap',
          textOverflow: 'ellipsis',
          textAlign: 'right',
        }}
      >
        {props.renderedCellValue}
      </Typography>
    </Tooltip>
  )
}

export const useProjectListColumns = () => {
  const { t } = useTranslation()

  const columns: MRT_ColumnDef<ProjectSummaryDto>[] = useMemo(() => {
    return [
      {
        size: 100,
        minSize: 50,
        id: 'lastOperation',
        accessorFn: (row) =>
          moment(row.lastOperation, 'YYYY-MM-DD').format('L'),
        header: t('common:last-modified'),
        Header: <LocalizedTypography translationKey="common:last-modified" />,
        sortDescFirst: true,
        Cell: DefaultTypography,
        enableClickToCopy: false,
      },
      {
        size: 100,
        minSize: 20,
        id: 'status',
        Header: <LocalizedTypography translationKey="common:status" />,
        accessorFn: (row) => row.status,
        header: t('common:status'),
        Cell: (_props) => {
          return (
            <LocalizedTypography
              translationKey={`project:${_props.row.original.status}`}
            />
          )
        },
        enableClickToCopy: false,
      },
      {
        size: 150,
        minSize: 20,
        id: 'orderNumber',
        Header: <LocalizedTypography translationKey="common:order-number" />,
        accessorFn: (row) => row.orderNumber,
        header: t('common:order-number'),
        enableGrouping: false,
        muiTableBodyCellProps: {
          align: 'left',
        },
        Cell: DefaultTypography,
        enableClickToCopy: false,
      },
      {
        size: 150,
        minSize: 20,
        id: 'projectReference',
        Header: (
          <LocalizedTypography translationKey="common:project-reference" />
        ),
        accessorFn: (row) => row.projectReference,
        header: t('common:project-reference'),
        enableGrouping: false,
        Cell: DefaultTypography,
        visibleInShowHideMenu: false,
        enableClickToCopy: false,
      },
      {
        size: 80,
        minSize: 20,
        id: 'numberOfRows',
        Header: <LocalizedTypography translationKey="common:num-parts" />,
        enableGrouping: false,
        accessorFn: (row) => row.numberOfRows,
        header: t('common:num-parts'),
        muiTableBodyCellProps: {
          align: 'center',
        },
        Cell: DefaultTypography,
        enableClickToCopy: false,
      },
      {
        size: 120,
        minSize: 20,
        id: 'salesPriceOfItemsAmount',
        Header: (
          <LocalizedTypography translationKey="common:sales-price-of-items" />
        ),
        muiTableHeadCellProps: {
          sx: {
            '& > div': {
              justifyContent: 'flex-end',
            },
          },
        },
        muiTableBodyCellProps: {
          align: 'right',
        },
        accessorFn: (row) => MoneyString(row.salesPriceOfItems),
        header: t('common:sales-price-of-items'),
        enableGrouping: false,
        Cell: DefaultTypography,
        enableClickToCopy: false,
      },
      {
        size: 100,
        minSize: 20,
        id: 'quotationExpireDate',
        Header: <LocalizedTypography translationKey="common:expires" />,
        header: t('common:expires'),
        accessorFn: (row) => row.quotationExpiresAt,
        muiTableBodyCellProps: {
          align: 'center',
          sx: {
            paddingBottom: 0,
          },
        },
        Cell: (rowData) => {
          if (!rowData.row.original.quotationExpiresAt) {
            return null
          }

          if (
            !statesToRenderExpirationDate.includes(
              rowData.row.original.status.toUpperCase() as ProjectStateAsString
            )
          ) {
            return null
          }

          return (
            <div
              style={{
                display: 'flex',
                alignItems: 'flex-end',
              }}
            >
              <ExpirationDateProgressVariant
                expirationDate={rowData.row.original.quotationExpiresAt}
                disableLabel
              />
            </div>
          )
        },
        enableClickToCopy: true,
      },
      {
        size: 150,
        minSize: 100,
        id: 'buyingPartyName',
        Header: <LocalizedTypography translationKey="common:customer" />,
        accessorFn: (row) => row.buyingPartyName,
        header: t('common:customer'),
        enableGrouping: true,
        Cell: DefaultTypography,
        enableClickToCopy: true,
      },
      {
        size: 100,
        minSize: 40,
        id: 'contactPersonEmail',
        Header: <LocalizedTypography translationKey="common:contact-person" />,
        accessorFn: (row) => row.contactPerson?.email,
        header: t('common:contact-person'),
        Cell: DefaultTypography,
        enableClickToCopy: true,
      },
      {
        size: 100,
        minSize: 40,
        id: 'sellingPartyName',
        Header: <LocalizedTypography translationKey="common:supplier" />,
        accessorFn: (row) => row.sellingPartyName,
        header: t('common:supplier'),
        Cell: DefaultTypography,
        enableClickToCopy: true,
      },
      {
        size: 100,
        minSize: 40,

        id: 'salesPersonEmail',
        Header: (
          <LocalizedTypography translationKey="common:sales-representative" />
        ),
        accessorFn: (row) => row.salesPerson?.email,
        header: t('common:sales-representative'),
        Cell: DefaultTypography,
        enableClickToCopy: true,
      },
      {
        size: 100,
        minSize: 40,
        id: 'creatorEmail',
        Header: <LocalizedTypography translationKey="common:created-by" />,
        accessorFn: (row) => row.creator?.email,
        header: t('common:created-by'),
        Cell: DefaultTypography,
        enableClickToCopy: true,
      },
    ] satisfies MRT_ColumnDef<ProjectSummaryDto>[]
  }, [t])

  return columns
}
