import { DeleteOutline, ReplayOutlined } from '@mui/icons-material'
import {
  Button,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  IconButton,
  Typography,
} from '@mui/material'
import { ProjectStateAsString } from 'controllers/Project/ProjectStateMachine'
import { ProjectsSummariesController } from 'controllers/Projects/ProjectsSummariesController'
import { useAppController } from 'customHooks/useAppController'
import { useClientStorage } from 'customHooks/useClientStorage'
import { InProductionProjectStatuses } from 'features/BillOfMaterials/components/ProjectStatus/InProductionProjectStatuses'
import { fullUnloadActiveProject } from 'features/BillOfMaterials/store/asyncActions/fullUnloadActiveProject'
import { SignalRService } from 'features/SignalR/service/SignalRService'
import {
  MaterialReactTable,
  MRT_PaginationState,
  MRT_SortingState,
  MRT_TableInstance,
  MRT_VisibilityState,
} from 'material-react-table'
import {
  ChangeEvent,
  SetStateAction,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { ProjectSummaryDto } from 'services/APIs/InternalAPI/internal-api.contracts'
import { useAppDispatch, useAppSelector } from 'store/configureStore'
import { deleteProjects } from 'store/ProjectList/asyncActions/deleteProjects'
import { useShowException } from 'utils/useShowException'
import { useProjectListColumns } from './_projectListColumns'
import useLocalizedMaterialReactTable from './useLocalizedMaterialReactTable'

export const NewProjectList = () => {
  const { controller, loading } = useAppController(
    () => new ProjectsSummariesController()
  )
  const partyId = useAppSelector((state) => state.user?.organizationContext?.id)

  const ShowException = useShowException('projects')
  const [onlyMyProjects, setOnlyMyProjects] = useClientStorage<boolean>(
    'filterMyProjects',
    false
  )
  const [showCanceledProjects, setShowCanceledProjects] =
    useClientStorage<boolean>('showCanceledProjects', false)

  const { t } = useTranslation()

  const _columns = useProjectListColumns()

  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  const [projectList, setProjectList] = useState<ProjectSummaryDto[]>([])

  const [currentQuery, setCurrentQuery] = useClientStorage('currentQuery', '')

  const [pagination, setPagination] = useClientStorage<MRT_PaginationState>(
    'project-list-pagination',
    {
      pageIndex: 0,
      pageSize: 50,
    }
  )

  const [sorting, setSorting] = useState<MRT_SortingState>([
    {
      desc: true,
      id: 'lastOperation',
    },
  ])

  const [columnVisibility, setColumnVisibility] =
    useClientStorage<MRT_VisibilityState>('columnsVisibility', {})

  const [rowCount, setRowCount] = useState(0)

  const getProjectList = useCallback(async () => {
    try {
      const resp = await controller.GetProjectsListPaged(
        onlyMyProjects,
        showCanceledProjects,
        {
          page: pagination.pageIndex,
          pageSize: pagination.pageSize,
          search: currentQuery,
          orderBy: sorting?.[0]?.id as keyof ProjectSummaryDto,
          orderDirection: sorting?.[0]?.desc ? 'desc' : 'asc',
        }
      )

      setProjectList((curr) => resp?.details || curr)
      setRowCount((curr) => resp?.totalCount || curr)
    } catch (err) {
      ShowException(err)
    }
  }, [
    ShowException,
    controller,
    currentQuery,
    onlyMyProjects,
    pagination.pageIndex,
    pagination.pageSize,
    showCanceledProjects,
    sorting,
  ])

  const handleDeleteProject = async (
    projectId: string,
    projectStatus: ProjectStateAsString
  ) => {
    try {
      dispatch(
        deleteProjects({
          requests: [{ projectId: projectId, projectStatus: projectStatus }],
          successCallback: () => {
            setProjectList(projectList.filter((x) => x.id !== projectId))
          },
        })
      )
    } catch (err) {
      ShowException(err)
    }
  }

  const handleDeleteSelectedProjects = async (
    table: MRT_TableInstance<ProjectSummaryDto>
  ) => {
    const selectedProjectsIds = table.getState().rowSelection
    const deleteRequests = Object.keys(selectedProjectsIds).map((x) => {
      return {
        projectId: x,
        projectStatus: projectList
          .find((p) => p.id === x)
          ?.status.toUpperCase() as ProjectStateAsString,
      }
    })

    dispatch(
      deleteProjects({
        requests: deleteRequests,
        successCallback: (projectIds) => {
          setProjectList(projectList.filter((x) => !projectIds.includes(x.id)))
          table.setState((state) => ({ ...state, rowSelection: {} }))
        },
      })
    )
  }

  const handleChangeOnlyMyProjects = (
    _: ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => {
    setOnlyMyProjects(checked)
  }

  useEffect(() => {
    getProjectList()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getProjectList])

  useEffect(() => {
    SignalRService.GetHub().then((hub) => {
      let timeout: NodeJS.Timeout

      hub.registerHandler('onProjectsListUpdates', () => {
        clearTimeout(timeout)

        timeout = setTimeout(() => {
          getProjectList()
        }, 1500)
      })

      hub.JoinGroup(`${partyId}_project_list`, () => {
        console.info('connected')
      })
    })

    return () => {
      SignalRService.GetHub().then((hub) => {
        hub.LeaveGroup(`${partyId}_project_list`, () => {
          console.info('disconnected from project list')
        })
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [partyId])

  const [filterValue, setFilterValue] = useState<string>(currentQuery)
  const filterTimeout = useRef<NodeJS.Timeout>()

  const handleFilterChange = async (filterValue: string) => {
    try {
      setFilterValue(filterValue)

      if (filterTimeout.current) {
        clearTimeout(filterTimeout.current)
      }

      filterTimeout.current = setTimeout(() => {
        setCurrentQuery(filterValue)
      }, 500)
    } catch (err) {
      ShowException(err)
    }
  }

  const tableOptions = useLocalizedMaterialReactTable({
    columns: _columns,
    data: projectList,
    getRowId: (row) => row.id,
    initialState: {
      density: 'compact',
      showGlobalFilter: true,
    },
    state: {
      isLoading: loading['GetProjectsListPaged'] && !projectList?.length,
      globalFilter: filterValue,
      columnVisibility: columnVisibility,
      pagination: pagination,
      sorting: sorting,
    },
    manualSorting: true,
    onSortingChange: setSorting,
    rowCount: rowCount,
    enableMultiSort: false,
    enableClickToCopy: true,
    enableStickyHeader: true,
    enableGrouping: true,
    enableRowSelection: true,
    enableColumnFilters: false,
    enableRowActions: true,
    positionActionsColumn: 'last',
    enableFullScreenToggle: false,
    enableDensityToggle: false,
    layoutMode: 'grid',
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onColumnVisibilityChange: (updater: any) => {
      const value = updater()

      if (Object.keys(value)[0] === 'projectReference') {
        return
      }

      setColumnVisibility(updater as SetStateAction<MRT_VisibilityState>)
    },
    manualPagination: true,
    onPaginationChange: (pagination) => {
      setPagination(pagination)
    },
    manualFiltering: true,
    onGlobalFilterChange: (filterValue) => {
      handleFilterChange(filterValue)
    },
    renderRowActions: (rowActionsProps) => {
      if (
        InProductionProjectStatuses.includes(
          rowActionsProps.row.original.status.toUpperCase() as ProjectStateAsString
        )
      ) {
        return null
      }

      return (
        <IconButton
          onClick={(e) => {
            e.stopPropagation()
            handleDeleteProject(
              rowActionsProps.row.original.id,
              rowActionsProps.row.original.status.toUpperCase() as ProjectStateAsString
            )
          }}
        >
          <DeleteOutline />
        </IconButton>
      )
    },
    renderTopToolbarCustomActions: (_props) => {
      return (
        <div
          style={{
            display: 'flex',
            gap: '2rem',
            alignItems: 'center',
          }}
        >
          <IconButton
            size="small"
            onClick={() => {
              getProjectList()
            }}
          >
            {loading['GetProjectsListPaged'] ? (
              <CircularProgress size={16} />
            ) : (
              <ReplayOutlined />
            )}
          </IconButton>
          <FormControlLabel
            control={
              <Checkbox
                size="small"
                checked={onlyMyProjects}
                onChange={handleChangeOnlyMyProjects}
              />
            }
            label={
              <Typography variant="caption">
                {t('common:my-projects', 'my projects')}
              </Typography>
            }
          />
          <FormControlLabel
            control={
              <Checkbox
                size="small"
                checked={showCanceledProjects}
                onChange={(e, checked) => setShowCanceledProjects(checked)}
              />
            }
            label={
              <Typography variant="caption">
                {t(
                  'common:show-canceled-projects-label',
                  'canceled or expired projects'
                )}
              </Typography>
            }
          />
          {Object.keys(_props.table.getState().rowSelection || {})?.length ? (
            <>
              {/* <Button
                color="primary"
                variant="outlined"
                onClick={() => {
                  Object.keys(
                    _props.table.getState().rowSelection || {}
                  ).forEach((x) => {
                    const anchor = document.createElement('a')
                    anchor.href = `${window.location.origin}/app/project/${x}`
                    anchor.target = '_blank'
                    anchor.rel = 'noopener noreferrer'

                    const clickEvent = new MouseEvent('click', {
                      ctrlKey: true,
                      altKey: true,
                      metaKey: true,
                      // metaKey: true,
                    })

                    anchor.dispatchEvent(clickEvent)
                  })
                }}
              >
                Open selected projects
              </Button> */}
              <Button
                color="error"
                variant="outlined"
                onClick={() => handleDeleteSelectedProjects(_props.table)}
              >
                Delete selected projects
              </Button>
            </>
          ) : null}
        </div>
      )
    },

    muiTableBodyRowProps: (rowProps) => {
      return {
        onClick: (e) => {
          dispatch(fullUnloadActiveProject())

          if (e.ctrlKey || e.metaKey) {
            window.open(
              `${window.location.origin}/app/project/${rowProps.row.original.id}`,
              '_blank',
              'rel=noopener noreferrer'
            )
          } else {
            navigate(`/app/project/${rowProps.row.original.id}`)
          }
        },
        sx: {
          cursor: 'pointer',
        },
      }
    },
    muiTableContainerProps: {
      style: { height: 'calc(100vh - 308px)' },
    },
    muiSearchTextFieldProps: {
      variant: 'standard',
      InputProps: {
        sx: {
          '& input': {
            width: {
              xs: '100%',
              sm: '100%',
              md: '100%',
              lg: '30vw',
              xl: '30vw',
            },
          },
        },
      },
    },
    enableColumnDragAndDrop: false,
    displayColumnDefOptions: {
      'mrt-row-actions': {
        visibleInShowHideMenu: false,
      },
      'mrt-row-select': {
        visibleInShowHideMenu: false,
      },
    },
  })

  return (
    <div>
      <MaterialReactTable table={tableOptions} />
    </div>
  )
}
